import React from "react";
import { Box } from "@mantine/core";
import { useIsSmallScreen } from "../Components/Utils";
import Logo from "./Logo";

const HeaderSkeleton = () => {
  const isSmallScreen = useIsSmallScreen();
  const dark =
    localStorage.getItem("colorScheme") !== "light" &&
    (window.location.host.includes("eigenphi") ||
      window.location.host === "localhost:3000");

  return (
    <Box
      h={80}
      w="100%"
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 100,
        background: dark ? "rgb(26, 27, 30)" : "#FFFFFF",
        boxShadow:
          "0 1px 3px rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0px 10px 15px -5px, rgba(0, 0, 0, 0.04) 0px 7px 7px -5px",
        padding: 16,
        borderBottom: dark ? "1px solid rgb(44, 46, 51)" : "1px solid #dee2e6",
      }}
    >
      <Box
        sx={{
          height: 46,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Logo dark={dark} isSmallScreen={isSmallScreen} />
      </Box>
    </Box>
  );
};

export default HeaderSkeleton;
