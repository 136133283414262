import React from "react";
import { Box } from "@mantine/core";
import HeaderSkeleton from "./HeaderSkeleton";

const ArbitrageScanSkeleton = () => {
  return (
    <Box>
      <HeaderSkeleton />
      <div>FETCHING DATA...</div>
    </Box>
  );
};

export default ArbitrageScanSkeleton;
