import "./wdyr"; // <--- first import
/// <reference types="@welldone-software/why-did-you-render" />
import { Buffer } from "buffer";
import React, { lazy, Suspense, StrictMode } from "react";
import ReactDOM from "react-dom";
import { useMantineTheme } from "@mantine/core";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import ArbitrageScanSkeleton from "./ArbitrageScanSkeleton/ArbitrageScanSkeleton";
global.Buffer = Buffer;
const ArbitrageScanApp = lazy(
  () =>
    import(
      /* webpackChunkName: "ArbitrageScanApp" */ "./ArbitrageScanApp/ArbitrageScanApp"
    )
);
const EigenApiApp = lazy(
  () =>
    import(/* webpackChunkName: "EigenApiApp" */ "./EigenApiApp/EigenApiApp")
);

const InitApp = () => {
  const theme = useMantineTheme();

  const tableCSS = `
    tr a {
      color: ${theme.colorScheme === "dark" ? "#44957D" : "#54B498"} !important;
    }
    tr:hover a {
      text-decoration: underline;
      color: #44D7B6 !important;
    }
    tr:hover a:hover {
      text-decoration: underline;
      color: #44D7B6 !important;
    }
    tr:hover a span{
      text-decoration: underline;
    }
  `;

  return (
    <>
      <style>{tableCSS}</style>
      {/* <ArbitrageScanSkeleton /> */}
      <Suspense fallback={<ArbitrageScanSkeleton />}>
        <ArbitrageScanApp />
      </Suspense>
    </>
  );
};

if (process.env.REACT_APP_NAME === "arbitragescan") {
  ReactDOM.render(
    <StrictMode>
      <InitApp />
    </StrictMode>,
    document.getElementById("root")
  );
} else if (process.env.REACT_APP_NAME === "eigenapi") {
  ReactDOM.render(
    <StrictMode>
      <Suspense fallback={<div>FETCHING DATA...</div>}>
        <EigenApiApp />
      </Suspense>
    </StrictMode>,
    document.getElementById("root")
  );
}

// Service Worker registion
// unregister() register().
// serviceWorkerRegistration.unregister();
// make the whole serviceworker process into a promise so later on we can
// listen to it and in case new content is available a toast will be shown
window.isUpdateAvailable = new Promise(function (resolve, reject) {
  serviceWorkerRegistration.register({
    onUpdate: resolve,
    onError: reject,
  });
});
