import { useMediaQuery } from "@mantine/hooks";
import { formatISO9075 } from "date-fns";
import keccak from "keccak";

export function shrink(
  input: string,
  headLength: number = 5,
  tailLength: number = 5
) {
  return input
    ? `${input.slice(0, headLength)}…${input.slice(-tailLength)}`
    : "";
}

export function useIsSmallScreen() {
  return useMediaQuery("(max-width:768px)");
}
export function useIsSmallerThanMD() {
  return useMediaQuery("(max-width:1024px)");
}
export function useIsSmallerThanLG() {
  return useMediaQuery("(max-width:1280px)");
}

export function isiPadPro129() {
  return window.screen.width === 1366 && window.screen.height === 1024;
}

export function textContent(elem: any): string {
  if (!elem) {
    return "";
  }
  if (typeof elem === "string") {
    return elem;
  }
  const children = elem.props && elem.props.children;
  if (children instanceof Array) {
    return children.map(textContent).join("");
  }
  return textContent(children);
}

export function amount(val: string | number, digits = 6) {
  const value = typeof val === "string" ? parseFloat(val) : val;
  return value || value === 0
    ? value.toLocaleString("zh-CN", {
        minimumFractionDigits: digits ?? 2,
        maximumFractionDigits: digits ?? 2,
      })
    : null;
}

export function amountPlus(val: string | number, digits = 6) {
  const value = typeof val === "string" ? parseFloat(val) : val;
  if (Math.abs(value) < 1) {
    let strValue = scientificNotationToString(value);
    strValue = `${strValue}`;

    const SUPERSCRIPTS: any = {
      "0": "₀",
      "1": "₁",
      "2": "₂",
      "3": "₃",
      "4": "₄",
      "5": "₅",
      "6": "₆",
      "7": "₇",
      "8": "₈",
      "9": "₉",
      "10": "₁₀",
      "11": "₁₁",
      "12": "₁₂",
      "13": "₁₃",
      "14": "₁₄",
      "15": "₁₅",
      "16": "₁₆",
      "17": "₁₇",
      "18": "₁₈",
    };

    if (strValue.includes(".")) {
      const decimal = strValue.split(".")[1];
      let zeroNumber = 0; // '0'的个数

      for (let i = 0; i < decimal.length; i++) {
        if (decimal[i] === "0") {
          zeroNumber++;
        } else {
          break;
        }
      }

      if (zeroNumber >= digits) {
        const last = decimal.substring(zeroNumber, zeroNumber + digits - 1);
        return `0.0${SUPERSCRIPTS[zeroNumber]}${last}`;
      } else {
        return amount(val, digits);
      }
    } else {
      return amount(val, digits);
    }
  } else {
    return amount(val, digits);
  }
}

/**
 * @description 科学计数法转为string
 * @param {string, number} param
 */
function scientificNotationToString(param: number | string) {
  let strParam = String(param || 0);
  let flag = /e/.test(strParam);
  if (!flag) return param;

  // 指数符号 true: 正，false: 负
  let sysbol = true;
  if (/e-/.test(strParam)) {
    sysbol = false;
  }
  // 指数
  let index = Number((strParam as any)?.match(/\d+$/)[0]);
  // 基数
  // eslint-disable-next-line
  let basis = (strParam as any)?.match(/^[\d\.]+/)[0].replace(/\./, "");

  if (sysbol) {
    return basis.padEnd(index + 1, "0");
  } else {
    return basis.padStart(index + basis.length, "0").replace(/^0/, "0.");
  }
}

export function formatTimestamp(
  timestamp: number,
  representation: "complete" | "date" | "time" | undefined = "complete"
) {
  return timestamp
    ? formatISO9075(new Date(Number(timestamp) * 1000), {
        representation,
      })
    : "";
}

export function formatUTC(timestamp: number) {
  const date = new Date(timestamp * 1000);
  const D: Array<any> = [
    date.getUTCFullYear(),
    date.getUTCMonth() + 1,
    date.getUTCDate(),
  ];
  const T: Array<any> = [
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  ];

  let i = 3;
  while (i) {
    --i;
    if (D[i] < 10) D[i] = "0" + D[i];
    if (T[i] < 10) T[i] = "0" + T[i];
  }

  return D.join("-") + " " + T.join(":");
}

type ItemWithTimestamp = { timestamp: number; [key: string]: any };
export function compressByTimestamp<T extends ItemWithTimestamp>(
  plist: Array<T>,
  {
    splitSeconds = 10 * 60,
    sampling = "MIN&MAX",
    keyName,
  }: {
    splitSeconds?: number;
    sampling?: "MIN" | "MAX" | "MIN&MAX" | "DISTINCT";
    keyName: string;
  }
) {
  const list = plist.concat();

  list.sort((p, n) => p.timestamp - n.timestamp);
  const startTime = list[0].timestamp;
  const endTime = list[list.length - 1].timestamp;
  const hash: {
    [timestart: string]: Array<T>;
  } = {};

  Array.from({ length: (endTime - startTime) / splitSeconds }).forEach(
    (_, idx) => {
      const timestart = startTime + idx * splitSeconds;
      const timeend = startTime + (idx + 1) * splitSeconds;

      hash[timestart] = hash[timestart] ?? [];

      while (
        list[0] &&
        list[0].timestamp >= timestart &&
        list[0].timestamp < timeend
      ) {
        hash[timestart].push(list.shift()!);
      }
    }
  );
  const filterList: Array<T> = [];

  Object.values(hash).forEach((items) => {
    if (items.length) {
      if (sampling === "DISTINCT") {
        const hash: {
          [k: string]: T;
        } = {};
        items.forEach((item) => {
          if (hash[item[keyName]] === undefined) {
            hash[item[keyName]] = item;
            filterList.push(item);
          }
        });
      } else {
        const sortList = items.sort(
          (a, b) => ((a[keyName] as number) - b[keyName]) as number
        );

        if (sampling === "MIN") {
          filterList.push(sortList[0]);
        }
        if (sampling === "MAX") {
          filterList.push(sortList[sortList.length - 1]);
        }
        if (sampling === "MIN&MAX") {
          filterList.push(sortList[0]);
          if (items.length > 1) {
            filterList.push(sortList[sortList.length - 1]);
          }
        }
      }
    }
  });
  return filterList;
}

export function nFormatter(num: number, digits: number = 2) {
  if (Math.abs(num) > 0 && Math.abs(num) < 1) {
    return num.toFixed(digits);
  }

  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return Math.abs(num) >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}

export function formatPeriod(period: string) {
  return (
    {
      1: "24H",
      7: "7D",
      30: "30D",
    }[period] || ""
  );
}

// 判断是否为空
export const isEmpty = (obj: any) => {
  return !obj || Object.keys(obj).length === 0;
};

export const isMinerText = (blockNumber: number, chain: string) => {
  if (chain !== "ethereum" || blockNumber < 15537394) return true;

  return false;
};

// 将全小写的地址转换为混合大小写
export const toChecksumAddress = (address: string) => {
  if (!address?.length) return "";

  address = address.toLowerCase().replace("0x", "");
  var hash = keccak("keccak256").update(address).digest("hex");
  var ret = "0x";

  for (var i = 0; i < address.length; i++) {
    if (parseInt(hash[i], 16) >= 8) {
      ret += address[i].toUpperCase();
    } else {
      ret += address[i];
    }
  }

  return ret;
};

export const copyTextToClipboard = (text: string): void => {
  const textarea = document.createElement("textarea");
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  textarea.setSelectionRange(0, textarea.value.length);
  document.execCommand("copy");
  document.body.removeChild(textarea);
};

export function convertTableToCSVAndDownload({
  tableId,
  fileName,
}: {
  tableId: string;
  fileName: string;
}): void {
  const table: HTMLTableElement = document.getElementById(
    tableId
  ) as HTMLTableElement;
  const rows: HTMLCollectionOf<HTMLTableRowElement> =
    table.getElementsByTagName("tr");
  const columnCount: number = table.rows[0].cells.length;
  const emptyColumnIndexes: Set<number> = new Set();

  for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
    const isColumnEmpty: boolean = Array.from(rows).every((row, rowIndex) => {
      const cell: HTMLTableCellElement = row.cells[columnIndex];
      const cellContent = cell.dataset["orig"]
        ? cell.dataset["orig"].replaceAll(",", "")
        : cell.textContent?.replaceAll(",", "");

      return cellContent?.trim() === "";
    });

    if (isColumnEmpty) {
      emptyColumnIndexes.add(columnIndex);
    }
  }

  const csvContent: string = Array.from(rows)
    .map((row, rowIndex) => {
      const cells: HTMLCollectionOf<HTMLTableCellElement> =
        row.getElementsByTagName("td");

      const rowContent: string = Array.from(cells)
        .filter((cell, columnIndex) => !emptyColumnIndexes.has(columnIndex))
        .map((cell) =>
          cell.dataset["orig"]
            ? cell.dataset["orig"].replaceAll(",", "")
            : cell.textContent?.replaceAll(",", "")
        )
        .join(",");

      return rowContent;
    })
    .filter((rowContent) => rowContent !== "")
    .join("\n");

  const blob: Blob = new Blob([csvContent], {
    type: "text/csv;charset=utf-8;",
  });

  const downloadLink: HTMLAnchorElement = document.createElement("a");
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = fileName;
  downloadLink.innerHTML = "download";

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}
